
.loading-page{
    opacity: 1;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* padding: 0 12px; */
    overflow: hidden !important;
    transition: opacity .5s linear, transform .5s ease-out;
    height: 100vh;
}
.loading-page_disable {
    opacity: 0;
    /* transform: translateX(-100%); */
}
.loading-page__center-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 100%;
    margin-top: -25px;
}
.loading-page__logo {
    display: block;
    margin: 0 auto;
    width: 130px;
}
.loading-page__text {
    color: #FFF;
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: -0.5455838441848755px;
    text-align: center;
    max-width: 180px;
    margin: 0 auto;
}

.loading-page__loading-block{
    margin-left: auto;
    margin-right: auto;
}

.loading-block{
   width: calc(100% - 56px);
   height: 3px;
   box-sizing: border-box;
   background: rgba(217, 217, 217, .2);
   border-radius: 158px;
}

.container__loading-block{
    margin-top: auto;
}


.loading-block__bar{
    height: 100%;
    background: rgba(192, 38, 217, 1);
    border-radius: 158px;
    width: 0;
    animation: loading 2.5s;
    animation-fill-mode: forwards;
    box-sizing: border-box;
}

.loading-wrapper{
    box-sizing: border-box;
    transform: translateY(-75px);
    margin: 0 auto;
    max-width: 400px;
    margin-top: auto;
    width: 100%;
    position: relative;
    z-index: 10;
}

@keyframes loading {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.loading-page__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}