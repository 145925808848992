
.snackbar-faq {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility .24s, opacity .24s linear;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "VK";
}
.snackbar-faq_active {
  visibility: visible;
  opacity: 1;
}
.snackbar-faq__content {
  /* transform: translateY(100%); */
  position: relative;
  background: #FFF;
  border-radius: 17px;
  padding: 9px 8px 26px 8px;
  width: calc(100% - 38px);
  max-height: 90vh;
  box-sizing: border-box;
  transition: transform .3s linear;
}
.snackbar-faq_active .snackbar-faq__content {
  /* transform: translateY(0); */
}
.snackbar-faq__content-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.snackbar-faq__info {
  position: relative;
  background-color: #07f;
  border-radius: 17px;
  padding: 110px 16px 42px 21px;
  margin-bottom: 25px;
}
.snackbar-faq__info-arrow {
  position: absolute;
  left: 50%;
  top: -58px;
  transform: translateX(-50%);
}
.snackbar-faq__info-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 29px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #fff;
}
.snackbar-faq__info-subtitle {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #fff;
}
.snackbar-faq__info-subtitle-link {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: inherit;
}
.snackbar-faq__info-link {
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #000;
  display: block;
  width: 100%;
}