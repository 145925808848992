.coin {
  display: block;
  font-size: 32px;
  color: pink;
  position: fixed;
  pointer-events: none;
  animation: moveUp 2s forwards;
  z-index: 100;
}

@keyframes moveUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-200px);
    opacity: 0;
  }
}