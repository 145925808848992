.navbar {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding: 7px 8px 5px 8px;
    background-color: rgba(209, 235, 219, 1);
    border-radius: 20px 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    padding-bottom: 15px;
}

.navbar__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color .24s linear;
    box-sizing: border-box;
    padding: 5px 15px;
    background-color: transparent;
    border-radius: 15px;
}
.navbar__button:hover {
    cursor: pointer;
}
.navbar__button-img {
    width: 24px;
    height: 24px;
}
.navbar__button_active {
    background-color: rgba(0, 146, 58, 1);
}

.navbar__button-text {
    color: rgba(0, 146, 58, 1);
    font-family: "5ka Sans Design";
    font-size: 10px;
    font-weight: 400;
    line-height: 12.03px;
    letter-spacing: 0.11999999731779099px;
    text-align: center;
    transition: color .24s linear;
}
.navbar__button_active .navbar__button-text {
    color: #FFF;
}